var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('br'),_c('br'),_c('br'),_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}}),_c('br'),_c('br'),_c('br')],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"elevation":"10","dense":"","items-per-page":50,"headers":_vm.headers,"items":_vm.rooms,"item-key":"CODICE_STANZA","search":_vm.search,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
      itemsPerPageText: 'Stanze per pagina',
      itemsPerPageAllText: 'Tutte le stanze',
      itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
      pageText: '',
    }},on:{"click:row":_vm.SelectRoom},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'Stanze per pagina'"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"item.id",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":'/rooms/' + item.ID}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizza")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }